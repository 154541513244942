import { useEffect } from 'react';
import { useLocation } from '@gatsbyjs/reach-router';
import { useLocalization } from 'gatsby-theme-i18n';
import { Helmet } from 'react-helmet';

export type PpSeo = {
  description?: string | null | undefined;
  image?: string | null | undefined;
  siteUrl?: string | null | undefined;
  title?: string | null | undefined;
  titleTemplate?: string | null | undefined;
};

export function Seo({
  description,
  image,
  siteUrl,
  title,
  titleTemplate,
}: PpSeo): React.ReactElement | null {
  const location = useLocation();
  const { pathname } = location;
  const { locale } = useLocalization();

  useEffect(() => {
    window.dataLayer ||= [];
    window.dataLayer.push({ event: 'optimize.activate' });
  }, []);

  const url = siteUrl ? `${siteUrl}${pathname}` : undefined;

  return (
    <Helmet title={title || undefined} titleTemplate={titleTemplate || '%s | NOAH'}>
      <html lang={locale} />
      {description && <meta content={description} name="description" />}
      {image && <meta content={image} name="image" />}
      {url && <meta content={url} property="og:url" />}
      {title && <meta content={title} property="og:title" />}
      {description && <meta content={description} property="og:description" />}
      {image && <meta content={image} property="og:image" />}
      <meta content="summary_large_image" name="twitter:card" />
      {title && <meta content={title} name="twitter:title" />}
      {description && <meta content={description} name="twitter:description" />}
      {image && <meta content={image} name="twitter:image" />}
      <link href="https://noah.com" rel="canonical" />
    </Helmet>
  );
}
