import { Fragment, useEffect } from 'react';
import { css } from '@emotion/react';
import { navigate, useLocation } from '@gatsbyjs/reach-router';
import { Button, Container, Link as MuiLink, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import type { UnavailableRegionPageQuery } from '../../generated/types';
import { navigateToLocalizedPath } from '../components/i18n/utils';
import { Seo } from '../components/layout';

type PpUnavailableRegion = PageProps<UnavailableRegionPageQuery>;

function UnavailableRegion({ data: { content } }: PpUnavailableRegion): React.ReactElement {
  const theme = useTheme();
  const location = useLocation();

  useEffect(() => {
    navigateToLocalizedPath(location.pathname, navigate);
  }, [location.pathname]);

  const styles = {
    container: css`
      text-align: center;
      height: 100%;
      padding: ${theme.spacing(14, 0, 6)};
      ${theme.breakpoints.down('md')} {
        padding: ${theme.spacing(10, 3)};
      }
    `,
    image: css`
      width: 100%;
    `,
  };
  return (
    <Fragment>
      <Seo title="Region not supported" />
      <Container css={styles.container} maxWidth="sm">
        <Stack alignItems="center" spacing={4}>
          <Typography component="h1" variant="bodyHeadingM">
            {content?.frontmatter?.unavailableRegion?.title}
          </Typography>
          <Typography component="p" variant="paragraphBodyS">
            <MuiLink
              color="text.secondary"
              href="https://faq.noah.com/en/articles/6618974-what-are-noah-s-supported-countries"
              rel="noreferrer"
              target="_blank"
            >
              {content?.frontmatter?.unavailableRegion?.link}
            </MuiLink>
          </Typography>
          <Typography component="p" variant="paragraphBodyS">
            {content?.frontmatter?.unavailableRegion?.copy}
          </Typography>
          <Button color="primary" component="a" href="mailto:support@noah.com" variant="contained">
            {content?.frontmatter?.unavailableRegion?.button}
          </Button>
          {content?.frontmatter?.unavailableRegion?.image?.src && (
            <img
              alt={content?.frontmatter?.unavailableRegion?.image.alt || 'region not available'}
              css={styles.image}
              src={content?.frontmatter?.unavailableRegion?.image.src}
            />
          )}
        </Stack>
      </Container>
    </Fragment>
  );
}

export const query = graphql`
  query UnavailableRegionPage($locale: String!) {
    content: markdownRemark(frontmatter: { layout: { eq: "info" }, locale: { eq: $locale } }) {
      frontmatter {
        unavailableRegion {
          image {
            src
            alt
          }
          title
          copy
          button
          link
        }
        unavailableRegionSeo {
          title
          description
          image
        }
      }
    }
  }
`;

export default UnavailableRegion;
